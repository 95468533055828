import React from 'react'
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'
import { NextSeo, ArticleJsonLd } from 'next-seo'

const ContentMeta = ({
  authorName,
  customTitle,
  description,
  expirationTime,
  images,
  modifiedTime,
  publishedTime,
  publisherLogo,
  publisherName,
  section,
  canonicalUrl,
  tags,
  title,
  seoTitle,
  seoDescription
}) => {
  const config = {
    title,
    titleTemplate: customTitle && '%s',
    description,
    canonical: canonicalUrl,
    openGraph: {
      title: seoTitle || title,
      description: seoDescription || description,
      url: canonicalUrl,
      images,
      type: 'article',
      article: {
        publishedTime,
        modifiedTime,
        expirationTime,
        section,
        authors: [authorName],
        tags
      }
    }
  }
  return (
    <>
      <NextSeo {...config} />

      <ArticleJsonLd
        url={canonicalUrl}
        title={title}
        description={description}
        images={images ? images.map((image) => image.url) : []}
        datePublished={publishedTime}
        dateModified={modifiedTime}
        authorName={authorName}
        publisherName={publisherName}
        publisherLogo={publisherLogo} // https://developers.google.com/search/docs/data-types/article#logo-guidelines
      />
    </>
  )
}

ContentMeta.propTypes = {
  authorName: string,
  customTitle: bool,
  description: string.isRequired,
  expirationTime: string,
  images: arrayOf(
    shape({
      url: string,
      width: oneOfType([string, number]),
      height: oneOfType([string, number]),
      alt: string
    })
  ),
  modifiedTime: string,
  publishedTime: string,
  publisherLogo: string,
  publisherName: string,
  section: string,
  canonicalUrl: string.isRequired,
  tags: arrayOf(string),
  title: string.isRequired,
  seoTitle: string,
  seoDescription: string
}

export default ContentMeta
